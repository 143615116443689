.admin-table{
    max-width: 100vw;
    margin: 1rem;
    overflow: auto;
}
.search-input {
    width: 90vw;
    margin: 2rem;
}

.form-rows {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 10px;
    flex-wrap: wrap;
}
@media only screen and (max-width:1200px) {
    .search-input {
        width: 90vw;
        margin-right: 1rem;
    }
    .form-rows {
        display: flex;
        flex-direction: column;

        margin: 1rem;
        width: 80vw;
        flex-wrap: wrap;
    }
    .form-button{
        margin:1rem 1rem 0px 0px;
    }
    .member-update-modal{
        width: 98vw;
        margin-top: 6rem;
    }
}