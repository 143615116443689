.main{
    margin-top: 5rem;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-weight: 600;
    color:green;
    background-color: white;
}
.address{
    margin: 1rem;
    color: white;
}
.top-social{
    display: flex;
    flex-direction: row;
    margin: 2rem;
    justify-content: space-between;
}
.middle{
    display:flex;
    flex-direction:row;
    justify-content:space-around;
    width: 90vw;
}
.social_div{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin:10px;
}

@media only screen and (max-width:1200px) {
    .middle{
        display:flex;
        flex-direction:column;
        justify-content:space-around;
    }
    .social_div{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin:10px;
        font-size: 12px;
    }
    .top-social{
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        justify-content: space-between;
    }
   
}