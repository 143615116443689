.pillar_content{
    margin-top: 5rem;
    
}
.pillar_header{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 10px;
    font-weight: bold;
    font-size: 25px;
    color: darkblue;
}
.pillar_info{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-right: 10vw;
    font-size: 20px;
    margin-left: 10px;

}
.list_item{
    margin-right: 20px;
    margin-bottom: 10px;
    font-size: 20px;
}
.pillars_h6{
    font-weight: bold;
    font-size: 20px;
    color: blue;
}