.carousel {

    min-height: 70vh;
    width: 100vw;
}

.carousel-image {
    display: block;
    width: fit-content;
    height: 600px;
    width: 100%;
}

.content {
    background-color: white;
    min-height: 100vh;
}

.card-banner {
    margin-top: 5rem;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
    width: 100vw;
    height: 100vh;
    background-image: url("https://res.cloudinary.com/dwnxsji2z/image/upload/v1668102733/IMG_8094_1_f9obgg.jpg");

    background-size: cover;
    background-repeat: no-repeat;
    transition: transform .5s;
    /* Animation */
    margin-right: 1rem;
    animation: fadeInLeft;
    /* referring directly to the animation's @keyframe declaration */
    animation-duration: 2s;
    /* don't forget to set a duration! */
}

.declaration {
    font-size: 22px;
    font-weight: 700;
    display: flex;
    margin: 2rem 2rem 2rem;
    color: black;
    flex-direction: column;

}

.pillars {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 1rem;
}

.card-pillars {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
    width: 25vw;
    min-height: 20rem;
    transition: transform .5s;
    /* Animation */
    margin-right: 1rem;
    animation: fadeInLeft;
    /* referring directly to the animation's @keyframe declaration */
    animation-duration: 2s;
    /* don't forget to set a duration! */
}

.card-pillars:hover {
    box-shadow: rgba(29, 185, 75, 0.25) 0px 30px 60px -12px inset, rgba(55, 122, 222, 0.3) 0px 18px 36px -18px inset;
    transform: scale(1.1);
    /* (110% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
    cursor: pointer;
    text-decoration: underline;
}

.card-title {
    color: black;
    font-size: 22px;
    margin: auto;
    font-weight: bold;

}

.ongoing_cards {
    display: flex;
    flex-direction: row;
}

.card-ongoing {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
    width: 30vw;
    min-height: 30rem;
    transition: transform .3s;
    /* Animation */
    margin-right: 1rem;
    animation: fadeInLeft;
    /* referring directly to the animation's @keyframe declaration */
    animation-duration: 2s;
    /* don't forget to set a duration! */
}

.card-ongoing:hover {
    box-shadow: rgba(29, 185, 75, 0.25) 0px 30px 60px -12px inset, rgba(55, 122, 222, 0.3) 0px 18px 36px -18px inset;
    transform: scale(1.04);
    /* (110% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
    cursor: pointer;

}

.card-ongoing-img {
    height: 300px;
    width: 100%;
}
.program-btn {
    background-color: darkblue;
    width: 50%;
}
.overlay {
    background: rgb(249, 249, 252);
    background: linear-gradient(90deg, rgba(249, 249, 252, 0) 3%, rgba(2, 16, 46, 0.8127626050420168) 100%);
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: flex-end;
}

.overlay-text {
    opacity: 0.9;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    left: 0;
    font-weight: 900;
    color: whitesmoke;
    width: 30vw;
    margin: 8rem 1rem 0px 0px;
    font-size: 28px;
}

@media only screen and (max-width:1200px) {
    .carousel {
        margin-top: 4rem;

        width: 100vw;
    }

    .carousel-image {
        height: 300px;
        width: 100%;
    }

    .card-pillars {
        box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
        width: 95vw;
        min-height: 20rem;
        transition: transform .5s;
        /* Animation */
        margin-bottom: 1rem;
    }

    .card-pillars:hover {
        box-shadow: rgba(29, 185, 75, 0.25) 0px 30px 60px -12px inset, rgba(55, 122, 222, 0.3) 0px 18px 36px -18px inset;
        transform: scale(1.1);
        /* (110% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
    }

    .card-ongoing {
        box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
        width: 95vw;

        min-height: 20rem;
        transition: transform .5s;
        /* Animation */
        margin-right: 1rem;
        animation: fadeInLeft;
        /* referring directly to the animation's @keyframe declaration */
        animation-duration: 2s;
        /* don't forget to set a duration! */
    }

    .card-banner {
        margin-top: 5rem;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
        width: 100vw;
        height: 35vh;
        background-image: url("https://res.cloudinary.com/dwnxsji2z/image/upload/v1668102733/IMG_8094_1_f9obgg.jpg");
        ;
        background-size: cover;
        background-repeat: no-repeat;
        transition: transform .5s;
        /* Animation */
        margin-right: 1rem;
        animation: fadeInLeft;
        /* referring directly to the animation's @keyframe declaration */
        animation-duration: 2s;
        /* don't forget to set a duration! */
    }

    .ongoing_cards {
        display: flex;
        flex-direction: column;
    }

    .card-ongoing {
        box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
        width: 95vw;
        min-height: 20rem;
        transition: transform .5s;
        /* Animation */
        margin-bottom: 1rem;
        animation: fadeInLeft;
        /* referring directly to the animation's @keyframe declaration */
        animation-duration: 2s;
        /* don't forget to set a duration! */
    }

    .program-btn {
        background-color: darkblue;
        width: 100%;
    }

    .overlay {
        background: rgb(249, 249, 252);
        background: linear-gradient(90deg, rgba(249, 249, 252, 0) 3%, rgba(2, 16, 46, 0.8127626050420168) 100%);
        width: 100vw;
        height: 35vh;
        display: flex;
        justify-content: flex-end;
        animation: fadeInLeft;
        /* referring directly to the animation's @keyframe declaration */
        animation-duration: 3s;
        /* don't forget to set a duration! */
    }

    .overlay-text {
        opacity: 0.9;
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        left: 0;
        font-weight: 900;
        color: whitesmoke;
        width: 30vw;
        margin: 2rem 1rem 10px 0px;
        font-size: 16px;
    }

}