.footer {
    background: linear-gradient(to bottom right, #ffffff 0%, purple 100%);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footer-top-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.copyright {
    display: flex;
    justify-content: center;
    color: black;
    margin: auto;
    margin-bottom: 20px;
    text-align: center;
    font-size: 18px
}

.contact_us {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    margin: 1rem;
    width: 30vw;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
}

.social_div_ {
    display: flex;
    flex-direction: row;
    margin: 2rem;
    width: 40vw;
    align-content: center;
    justify-content: center;
}

.social_div_row {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;

    margin: 0.5rem;
}

.social_div_title {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    text-decoration: underline;
}

@media only screen and (max-width:1200px) {

    .contact_us {
        display: flex;
        flex-direction: column;
        font-weight: bold;
        margin: 1rem;
        width: 95vw;
        text-align: center;
    }

    .social_div_ {
        display: flex;
        flex-direction: row;
        font-weight: bold;
        margin: 1rem;
        width: 90vw;
        text-align: center;
        justify-content: center;
    }

    .footer-top-row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}