.error-404 {
   
    
    height: 100vh;
    width: 100vw;
}
.image-404{
    margin-left: 30vw;

}
@media only screen and (max-width:1200px) {
    .error-404 {
   
        height: 100vh;
        width: 95vw;
    }
    .image-404{
       margin: 0;
    
    }
}