.impact_content{
    min-height: 100vh;
    margin-top: 5rem;
   
}
.reach{
    display: flex;
    flex-direction: row;
    justify-content:center ;
    margin: 1rem;
}

.reach-card{
    display: flex;
    flex-direction: column;
    justify-content:space-between ;
    font-size: 24px;
    min-width:30vw;
    margin: 1rem;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.reach-title{
    text-align: center;
    font-size: 28;
    color:"teal"
}
.counter-div{
    color: rgb(9, 24, 8);
    display: flex;
    flex-direction: row;
    justify-content:space-between ;
    margin:0px 1rem 0px 1rem;
}
.counter-div:hover{
    color: rgb(31, 203, 19);
    font-weight: 700;
    
}
.testmonial{
    display: flex;
    flex-direction: row;
    justify-content:center ;
    margin: auto;
    max-width: 80vw;
   max-height: 10rem;

}
.testmonial-card{
    margin: auto;
    margin-top: 5rem;
    
    max-width:60vw;
    height: fit-content;
    display: flex;
    flex-direction: row;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.avatar{
    border-radius: 50%;
    width: 15vw;
    margin-top: 10px;
}
.tesmonial-content{
    margin: 0px 1rem;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    color: darkblue;
}
@media only screen and (max-width:1200px) {
    .counter{
        font-size: 18px;
        font-weight: 400;
        display: flex;
        flex-direction: column;
        align-content:space-between ;
        margin: 1rem 1rem 0 2rem;
       
    }
    .testmonial{
        display: flex;
        flex-direction: row;
        justify-content:center ;
        margin: auto;
        max-width: 100vw;
     
    }
.testmonial-card{
    margin: 1rem;
    max-width: 99vw;
    height: fit-content;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 10px;
}
.avatar{
    border-radius: 50%;
    width: 40vw;
    margin: auto;
    
}
.reach-card{
    display: flex;
    flex-direction: column;
    justify-content:space-between ;
    font-size: 24px;
    min-width:90vw;
    margin: 1rem;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
}