.landing {
    display: flex;
    flex-direction: row;
    width: 100vw;
    background-image: url('https://res.cloudinary.com/dwnxsji2z/image/upload/v1666096565/website%20files/Disk-2s-1704px_fh9qtg.png');
    height: 100vh;

    background-repeat: no-repeat;
    background-size: cover;
}

.landing-left {
    width: 100%;
    border-top-left-radius: 20px;
    overflow-y: scroll;
    max-height: 90vh;
    margin-top: 5rem;
    background-repeat: no-repeat;
    background-size: cover;
}

.landing-right {
    width: 45vw;
    color: white;
    margin-top: 4.5rem;
}

.landing-right-header {
    text-align: center;
    margin-top: 1rem;
    font-size: 28px;
}

.corousel {
    width: 68vw;
    min-height: 100%;
    background-color: whitesmoke;
    box-shadow: rgba(57, 57, 232, 0.25) 0px 30px 60px -30px inset, rgba(104, 196, 226, 0.3) 0px 18px 36px -30px inset;
    border-top-left-radius: 20px;
}

.modal-corousel {
    width: 100%;
    min-height: 100%;
    background-color: whitesmoke;
    box-shadow: rgba(57, 57, 232, 0.25) 0px 30px 60px -30px inset, rgba(104, 196, 226, 0.3) 0px 18px 36px -30px inset;

}

.update-modal-title {
    display: flex;
    justify-content: space-between;
    color: teal;
    font-weight: 700;
    margin-bottom: 1rem;
    width: 100%;
    font-size: 20px;
}

.member-button {
    bottom: 5rem;
    right: 55vw;
    position: absolute;
    width: 10rem;
}

.auth {
    background-image: url('https://res.cloudinary.com/dwnxsji2z/image/upload/v1666096565/website%20files/Disk-2s-1704px_fh9qtg.png');
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 3rem;
}

.signin {
    display: flex;
    margin: auto;

}

.signup {
    display: flex;
    margin: auto;
    width: 100vw;
}

.sign-card {
    width: 20rem;
    margin: auto;
    margin-top: 2.5rem;
    width: 30vw;
    min-height: 50vh;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.logo-form {
    margin: auto;
}

.form-label {
    font-weight: 700;
}

.form-link {
    font-weight: 900;
    font-size: 18px;
    margin-left: 1rem;
    color:darkblue;
}

.form-link:hover {
    font-weight: 800;

    color: blue;
    cursor: pointer;
}

.signup-title {
    color: green;
    font-size: 18px;
    font-weight: bolder;
    margin: 10px;
}

.form-rows {
    display: flex;
    flex-direction: row;
   
    margin: 1rem;
    flex-wrap: wrap;
}
.signup_input{
    width:20rem;
}
.reset-card {

    margin: auto;
    margin-top: 2.5rem;
    width: 40vw;
    min-height: 50vh;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.search-input {
    width: 90vw;
    margin: 2rem;
}

.card-board {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
    width: 20vw;
    min-height: 18rem;
    transition: transform .5s;
    /* Animation */
    margin: 1rem;
    animation: fadeInUp;
    /* referring directly to the animation's @keyframe declaration */
    animation-duration: 2s;
    /* don't forget to set a duration! */
    box-shadow: rgba(29, 185, 75, 0.25) 0px 30px 60px -12px inset, rgba(55, 122, 222, 0.3) 0px 18px 36px -18px inset;
}

.card-board:hover {

    transform: scale(1.1);
    /* (110% zoom - Note: if the zoom is too large, it will go outside of the viewport) */

}

.member-rows {
    display: flex;
    flex-direction: row;
    justify-content: left;
    flex-wrap: wrap;
    margin-top: 1rem;
}
.profile_buttons{
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   margin: 1rem;
   flex-wrap: wrap;
}
@media only screen and (max-width:1200px) {
    .landing {
        display: flex;
        flex-direction: column;
        background-color: rgb(45, 193, 193);
        min-height: 100vh;
        width: 100vw;
    }

    .landing-right {
        display: none;
    }

    .landing-left {
        width: 100vw;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        background-color: rgb(239, 234, 241);
        margin-top: 5rem;
    }

    .corousel {

        width: 100%;
        min-height: 100vh;
        background-color: whitesmoke;
        box-shadow: rgba(57, 57, 232, 0.25) 0px 30px 60px -30px inset, rgba(104, 196, 226, 0.3) 0px 18px 36px -30px inset;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }

    .member-button {
        margin: 100px -60vw 100px 30px;


    }

    .list {
        font-size: 20px;
    }

    .sign-card {

        margin: auto;
        margin-top: 5rem;
        width: 90vw;
        min-height: 50vh;
        box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    }

    .logo-form {
        margin: auto;
    }

    .reset-card {

        margin: auto;
        margin-top: 2.5rem;
        width: 90vw;
        min-height: 50vh;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    }

    .form-rows {
        display: flex;
        flex-direction: column;

        margin-right: 10px;
        width: 90vw;
        flex-wrap: wrap;
    }
    .signup_input{
        width:90vw;
    }
    .card-board {
        box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
        width: 80vw;
        min-height: 18rem;
        transition: transform .5s;
        /* Animation */
        margin: 1rem;
        animation: fadeInUp;
        /* referring directly to the animation's @keyframe declaration */
        animation-duration: 2s;
        /* don't forget to set a duration! */
        box-shadow: rgba(29, 185, 75, 0.25) 0px 30px 60px -12px inset, rgba(55, 122, 222, 0.3) 0px 18px 36px -18px inset;
    }
    .search-input {
        width: 90vw;
        margin-right: 1rem;
    }
    .dashboard-score{
        margin-top: 1rem;
    }
    
}