.nav{
    background: whitesmoke;
  

}
.brand-name{
    font-size:28px;
    font-weight: 700;
    color:green;
}
.nav-menu{
    display:'flex';
    flex-direction: row;
    justify-content:flex-end;
}
.nav-links{
    font-size:20px;
    color:darkblue;
    font-weight: 700;
    margin-left: 15px;
    font-family: cursive;
}
.nav-links:hover{
    -ms-transform: scaleY(1.3); /* IE 9 */
    transform: scaleY(1.3); 
    font-weight: 800;
    color: green;
}
.donate-button{
    
    width: 5rem;
}

@media only screen and (max-width:1200px) {
    .brand-name{
        font-size:22px;
        font-weight: 900;
        color: teal;
    }
}